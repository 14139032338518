@import "../Variables/colors";
.old-browser {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background: #fafafa;

  &-body {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 500px;
    // padding: 20px;
    @media (max-width: 767px) {
      width: calc(100% - 30px);
    }
    .panel {
      margin: 20px auto;
    }
  }
  &-icon {
    img {
      display: block;
      margin: 20px auto 10px;
      height: 90px;
      width: 90px;
      object-fit: contain;
      @media (max-width: 767px) {
        height: 70px;
        width: 70px;
      }
    }
  }

  &-header {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    padding: 10px 0px;
    @media (max-width: 767px) {
      font-size: 16px;
      padding: 10px 0px 5px;
    }
  }

  &-subheader {
    max-width: 370px;
    margin: 4px auto 10px;
    font-size: 14px;
    line-height: 1.43;
    color: #5e7079;
    text-align: center;
  }

  &-options {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    .btn {
      @media (max-width: 767px) {
        &:not(:last-of-type) {
          margin-bottom: 15px;
        }
      }
      @media (min-width: 768px) {
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
      border-radius: 50px;
      margin: 0px auto 10px;
      display: block;
      min-width: 130px;
      img {
        height: 15px;
        width: 15px;
        position: relative;
        bottom: -3px;
        right: 5px;
        object-fit: contain;
      }
      &:hover {
        border: 1px solid $primaryColor;
        color: white;
        background: $primaryColor;
      }
    }
  }
}
