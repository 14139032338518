.js-error-content {
    max-width: 450px;
    margin: 0 auto;
     .js-error-panel {
         padding: 30px 20px;
         text-align: center;
         &-icon {
            img {
                width: 100px;
            }
         }
         &-header {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.2;
            text-align: center;
            padding: 10px 0px;
            @media(max-width: 767px) {
                font-size: 16px;
                padding: 10px 0px 5px;
            }
         }
         &-subheader {
            margin: 4px auto 10px;
            font-size: 15px;
            line-height: 1.5;
            color: #5e7079;
            text-align: center;
            @media(max-width: 767px) {
                font-size: 14px;
            }
         }
         &-button button{
            border-radius: 50px;
            margin: 20px auto 10px;
            display: block;
            min-width: 130px;
         }
     }
}