@import "../Variables/colors";
.bo-header-content {
  position: sticky;
  top: 0px;
  z-index: 16;
  height: 60px;
  background-color: #ffffff;
  color: #232f49;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.15);
}

.bo-body {
  min-height: calc(100vh - 60px);
}

.page-title {
  font-size: 18px;
}
@media screen and (max-width: 340px) {
  .bo-header-content {
    height: 60px;
  }
  .bo-body {
    min-height: calc(100vh - 60px);
  }
}
@media screen and (max-width: 450px) and (min-width: 341px) {
  .bo-header-content {
    height: 55px;
  }
  .bo-body {
    min-height: calc(100vh - 55px);
  }
}
.bo-header-content .bo-header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  color: white;
  background-color: #ffffff;
  padding: 0px 40px;

  color: #232f49;
}
@media screen and (max-width: 340px) {
  .bo-header-content .bo-header {
    height: 60px;
    padding: 0px 20px;
  }
  .bo-body {
    min-height: calc(100vh - 60px);
  }
}
@media screen and (max-width: 450px) and (min-width: 341px) {
  .bo-header-content .bo-header {
    height: 55px;
    padding: 0px 20px;
  }
  .bo-body {
    min-height: calc(100vh - 55px);
  }
}
.bo-header-content .bo-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bo-header-content .bo-header-left > div {
  margin-right: 30px;
}
@media screen and (max-width: 450px) {
  .bo-header-content .bo-header-left > div {
    margin-right: 0px;
  }
}
.bo-header-content .bo-header-left > div:last-of-type {
  margin-right: 0px;
}
.bo-header-content .bo-header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bo-header-content .bo-header-right > div {
  margin-right: 30px;
}
@media screen and (max-width: 340px) {
  .bo-header-content .bo-header-right > div {
    margin-right: 7px;
  }
}
@media screen and (max-width: 450px) and (min-width: 341px) {
  .bo-header-content .bo-header-right > div {
    margin-right: 0px;
  }
}
.bo-header-content .bo-header-right > div:last-of-type {
  margin-right: 0px;
}
.bo-header-content .bo-header-notif img {
  height: 25px;
  object-fit: cover;
}
.bo-header-notif {
  cursor: pointer;
}

.bo-header-notif svg {
  position: relative;
  bottom: -3px;
}

.bo-header-content .bo-header-user {
  display: flex;
}
@media screen and (max-width: 340px) {
  .bo-header-content .bo-header-user {
    display: none;
  }
}
@media screen and (max-width: 1014px) and (min-width: 341px) {
  .bo-header-content .bo-header-user {
    display: none;
  }
}

.bo-header-content .bo-header-user-avatar .user-avatar-placeholder {
  height: 40px;
  width: 40px;
  background-color: #fafafa;
  /* color: $primaryColor; */
}

.bo-header-content .bo-header-user-avatar img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  bottom: -3px;
}

.bo-header-content .bo-header-user-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
}
.bo-header-content .bo-header-user-details-name {
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 4px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bo-header-content .bo-header-user-details-service {
  font-weight: 400;
  font-size: 12px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bo-header-content .bo-header-user-details-id {
  font-size: 12px;
  padding-top: 4px;
  opacity: 0.7;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bo-header-content .bo-header-user-details-icon img {
  width: 22px;
}
.bo-header-content .bo-header-user-details .flex-child {
  padding: 0px 15px;
}
.bo-header-content .bo-header-user-details .flex-child:last-of-type {
  padding-left: 0px;
}
.bo-header-content .bo-header-user-details .sub-menu,
.bo-header-notif-all {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  min-width: 300px;
  box-shadow: -2px 9px 10px -1px rgba(0, 0, 0, 0.3);
  top: 40px;
  /* color: $primaryColor; */
  text-transform: capitalize;
  z-index: 10;
  right: 15px;
  border-radius: 5px;
  padding: 25px 15px;
  -webkit-transition: 100ms ease-in 0s;
  transition: 100ms ease-in 0s;
}
.bo-header-content .bo-header-user-details .sub-menu:before,
.bo-header-notif-all:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #fff;
  border-top: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}
.bo-header-content .bo-header-user-details .sub-menu ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.bo-header-content .bo-header-user-details .sub-menu ul li {
  padding: 15px;
  margin: 0px -15px;
  border-bottom: 1px solid #f9f9f9;
}
.bo-header-content .bo-header-user-details .sub-menu ul li a,
.bo-header-content .bo-header-user-details .sub-menu ul li > span {
  text-decoration: none !important;
  color: inherit;
  transition: 200ms all;
  margin: -15px;
  padding: 15px;
  display: block;
}
.bo-header-content .bo-header-user-details .sub-menu ul li a:hover {
  text-decoration: underline;
}
.bo-header-content .bo-header-user-details .sub-menu ul li:last-of-type {
  border-bottom: none;
  padding: 15px 15px 0px;
}
.bo-header-content .bo-header-user-details .sub-menu ul li:first-of-type {
  border-top: 1px solid #f9f9f9;
}
.bo-header-content .bo-header-user-details .sub-menu ul li.manage-business {
  display: flex;
  justify-content: space-between;
}
.bo-header-content
  .bo-header-user-details
  .sub-menu
  ul
  li.manage-business
  span {
  font-size: 13px;
  color: #61727b;
}
.bo-header-content .bo-header-user-details .sub-menu .select .select__input {
  padding: 9px 15px;
}
.bo-header-content .bo-header-user-details .sub-menu .switch-business {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bo-header-content
  .bo-header-user-details
  .sub-menu
  .switch-business
  .switch-text {
  font-size: 13px;
  color: #61727b;
  white-space: nowrap;
  margin-right: 10px;
  display: none;
}

.bo-header-content
  .bo-header-user-details
  .sub-menu
  .switch-business
  .select-wrapper {
  width: 100%;
}
.bo-header-content .bo-header-user-details:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px);
}

.sub-menu li img {
  height: 14px;
  width: 14px;
  margin-right: 7px;
  position: relative;
  bottom: -2px;
}
@media screen and (max-width: 1014px) {
  .bo-header-content .bo-header-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .bo-header-balance {
    display: none;
  }
}
.bo-header-content .bo-header-logo img.logo {
  cursor: pointer;
  width: 110px;
  object-fit: cover;
}
@media screen and (max-width: 340px) {
  .bo-header-content .bo-header-logo img.logo {
    width: 90px;
    margin-right: 12px;
  }
}
@media screen and (max-width: 450px) and (min-width: 341px) {
  .bo-header-content .bo-header-logo img.logo {
    width: 90px;
  }
}
.bo-header-content .bo-header-status span.the-status {
  font-weight: 500;
}
.bo-header-content .bo-header .bo-wallet-balance {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 3px;
}
@media screen and (max-width: 340px) {
  .bo-header-content .bo-header .bo-wallet-balance {
    font-size: 13px;
  }
}
.bo-header-content .bo-header .bo-wallet-balance-value {
  font-weight: 500;
  font-size: 20px;
}
.bo-wallet-balance-value a {
  color: inherit;
  text-decoration: none;
}
@media screen and (max-width: 340px) {
  .bo-header-content .bo-header .bo-wallet-balance-value {
    font-size: 17px;
  }
}
@media screen and (max-width: 450px) and (min-width: 341px) {
  .bo-header-content .bo-header .bo-wallet-balance-value {
    font-size: 18px;
  }
}
@media screen and (max-width: 340px) {
  .bo-header-mobile-menu {
    margin: 0px 0px 0px 0px;
    padding: 10px;
    margin-left: -10px;
    margin-top: 2px;
  }
}
@media screen and (max-width: 450px) and (min-width: 341px) {
  .bo-header-mobile-menu {
    margin: 0px 0px 0px 0px;
    padding: 15px;
    margin-left: -15px;
    margin-top: 2px;
  }
}
@media screen and (max-width: 780px) and (min-width: 451px) {
  .bo-header-mobile-menu {
    margin: 0px 0px 0px 0px;
    padding: 20px;
    margin-left: -20px;
    margin-top: 2px;
  }
}
@media screen and (max-width: 1014px) and (min-width: 781px) {
  .bo-header-mobile-menu {
    margin: 0px 0px 0px 0px;
    padding: 25px;
    margin-left: -25px;
    margin-top: 2px;
  }
}
@media screen and (max-width: 1014px) {
  .bo-header-status {
    display: none;
  }
}
@media screen and (min-width: 1015px) {
  .bo-header-mobile-menu {
    display: none;
  }
  .page-title {
    font-size: 22px;
  }
}

.bo-header-notif {
  position: relative;
}

.bo-header-notif .bo-header-notif-all.show-notif {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, 4px);
  -ms-transform: translate(-50%, 4px);
  transform: translate(-50%, 4px);
}

.bo-header-notif-all {
  min-width: 350px;
  padding: 0px 15px 0px;
  transform: translateX(-50%);
  left: 50%;
}

.notif-see-all {
  border-top: 1px solid #f1f1f1;
  margin: 0px -15px;
  padding: 12px 15px;
  text-align: center;
  a {
    text-decoration: none;
    color: $primaryColor;
    display: block;
    transition: 200ms all;
  }
}

a.notif-body-item {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: 200ms all;
}
a.notif-body-item:hover {
  background-color: rgba(237, 242, 250, 0.5);
}

.bo-header-notif-all .notif-header {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f1;
  padding: 16px 15px 12px;
  // text-align: center;
  margin: 0px -15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* color: $primaryColor; */
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: $primaryColor;
    font-size: 12px;
    font-weight: 400;
    text-transform: lowercase;
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.bo-header-notif-all .notif-header .unread-count {
  font-weight: 600;
  padding-right: 5px;
}

.bo-header-notif-all .notif-header-close {
  display: none;
}

.bo-header-notif-all .notif-body {
  margin: 0px -15px;
  max-height: 200px;
  overflow-y: auto;
}

.bo-header-notif-all .notif-body-item {
  padding: 17px 15px;
  position: relative;
}

.bo-header-notif-all .notif-body-item.false {
  background-color: rgba(237, 242, 250, 0.65);
}

.notif-body-item.no-notif {
  text-align: center;
  font-size: 13px;
  padding: 20px;
  text-transform: lowercase;
}

.notif-body-item.no-notif:first-letter {
  text-transform: capitalize;
}

.bo-header-notif-all .notif-body-item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.notif-body-item-title {
  /* color: $primaryColor; */
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.notif-body-item-body {
  color: #5e7079;
  font-size: 13px;
  opacity: 0.8;
  max-width: 100%;
  text-transform: lowercase;
}

.notif-body-item-body:first-letter {
  text-transform: uppercase;
}

.notif-body-item-time {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #5e7079;
  font-size: 9px;
  opacity: 0.8;
  text-transform: lowercase;
}

.bo-header-notif-all:before {
  border-bottom-color: #f9f9f9;
}

.bo-header-notif span.notif-count {
  background: #fe3e3e;
  width: 20px;
  height: 20px;
  position: absolute;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  top: 0px;
  left: 11px;
  /* margin: 5px; */
  z-index: 2;
}

@media (max-width: 1014px) {
  .notif-body-item-title {
    max-width: 250px;
  }

  .bo-header-notif-all .notif-header {
    background-color: #ffffff;
    margin: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* color: $primaryColor; */
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 3px 12px -2px rgba(0, 0, 0, 0.08);
    position: relative;
    padding-left: 40px;
  }

  .bo-header-notif-all .notif-header-close {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 15px;
    display: block;
  }

  .notif-body-item-body {
    max-width: 80%;
  }

  .bo-header-notif-all .notif-body {
    margin: 0px;
    max-height: none;
    overflow-y: auto;
    padding: 0px 0px;
    height: calc(100vh - 125px);
    position: relative;
  }

  @media (display-mode: standalone) {
    .bo-header-notif-all .notif-body {
      height: calc(100vh - 75px);
    }
  }

  .notif-body-item-time {
    right: 15px;
  }

  .bo-header-notif-all .notif-body-item {
    padding: 15px;
    position: relative;
  }

  .bo-header-notif-all .notif-header-mark {
    font-size: 12px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 15px;
    color: #3676c8;
    font-weight: 300;
    text-transform: initial;
  }

  .bo-header-notif-all .notif-header-close img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    position: relative;
    bottom: -3px;
  }

  .bo-header-notif .bo-header-notif-all.show-notif {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-100%, 0px);
    -ms-transform: translate(-100%, 0px);
    transform: translate(-100%, 0px);
  }

  .bo-header-notif-all {
    top: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    transform: none;
    left: 100%;
    min-width: auto;
    border-radius: 0px;
    padding: 0px;
  }
  .bo-header-notif-all:before {
    display: none;
  }
}
