@import "./Variables/colors";
html,
body {
  /* scroll-behavior: smooth; */
  /* height: 100%; */
}

pre {
  display: inline;
  margin: 0;
}

samp {
  /* font-weight: 600; */
  text-transform: uppercase;
  font-family: inherit;
}

/* one signal */

.onesignal-bell-container {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}

/* canva */

._9LY7bFNZktu_WL2gmvFiF {
  /* display: none;
   z-index: inherit; */
}

* {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.carousel .slide {
  background: transparent !important;
  text-align: inherit !important;
}

label {
  cursor: pointer;
}

/* Components */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  list-style-type: none;
}

img {
  border-style: none;
}

// div,
// p {
//   word-break: break-word;
// }

input,
textarea {
  box-sizing: border-box;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  opacity: 0.5;
}

::-webkit-input-placeholder {
  opacity: 0.5;
}

/* 
 div:empty, p:empty, a:empty  {
   display: none;
 } */

/* spinners */

.spinner {
  margin: 150px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  &-bar {
    background-color: #446c91;
    height: 100%;
    width: 6px;
    margin-right: 4px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  &-text {
    font-size: 13px;
    padding-top: 7px;
    color: #5e7079;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

.tooltip {
  color: white;
  font-weight: 400;
  background-color: #5e7079;
  padding: 9px;
  position: absolute;
  width: 250px;
  line-height: 1.5;
  right: 0px;
  top: 25px;
  z-index: 5;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 200ms all;
  text-transform: initial;

  &:before {
    content: "";
    top: -9px;
    right: 6px;
    position: absolute;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom-color: #5e7079;
    border-top: 0;
    filter: drop-shadow(1px -1px 1px rgba(255, 160, 0, 0.1));
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.success_checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #0dd471;
  fill: none;
  animation: success_stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.success_checkmark {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #0dd471;
  animation: success_fill 0.4s ease-in-out 0.4s forwards,
    success_scale 0.3s ease-in-out 0.9s both;
}

.success_checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: success_stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes success_stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes success_scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes success_fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #0dd471;
  }
}

/* tooltip */

.tooltip {
  color: white;
  font-weight: 400;
  background-color: #5e7079;
  padding: 9px;
  position: absolute;
  width: 250px;
  line-height: 1.5;
  right: 0px;
  top: 25px;
  z-index: 5;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 200ms all;
  text-transform: initial;

  &:before {
    content: "";
    top: -9px;
    right: 6px;
    position: absolute;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom-color: #5e7079;
    border-top: 0;
    filter: drop-shadow(1px -1px 1px rgba(255, 160, 0, 0.1));
  }
}

.transform-img {
  transform: rotate(180deg);
}

.pending-loader {
  border: 2px solid #f9f9f9;
  border-radius: 50%;
  border-top: 2px solid #d8d8d8;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* buttons */

.btn {
  color: #232f49;
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 11px 21px;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  font-family: inherit;
  &:active,
  &:focus {
    outline: none;
  }
  &.focus {
    @media (min-width: 1015px) {
      &:focus {
        // outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
}

.btn--primary {
  color: #fff;
  background-color: $primaryColor;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0 4px 5px -2px rgba(50, 50, 93, 0.11),
      0 1px 2px -2px rgba(0, 0, 0, 0.08);
  }
}

.btn-outline--blue {
  border: 1px solid $primaryColor;
  color: $primaryColor;
  background: transparent;

  &.is-loading {
    background: $primaryColor;
  }

  &:hover {
    color: white;
    background: $primaryColor;
  }
}

.btn--secondary {
  color: #fff;
  background-color: #ff6600;

  &:hover {
    background-color: #b84900;
  }
}

.btn--no-style {
  background: none;
  border: 0;
}

.btn--default {
  background-color: #fff;
  border-color: #d4d5d8;

  &:hover {
    background-color: #dbdbdb;
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--lg {
  font-size: 18px;
}

.btn--sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn--crv {
  min-width: 80px;
  border-radius: 32px;

  &.btn--sm {
    padding: 0.45rem 0.5rem !important;
  }

  &.btn--lg {
    padding: 0.45rem 0.9rem !important;
    font-size: 0.875rem;
  }

  &.btn--xs {
    font-size: 12px;
    padding: 0.25rem 0.3rem !important;
    min-width: 50px;
  }

  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    position: relative;
    bottom: -2px;
  }
}

.btn--flex {
  display: flex;
  align-items: center;
}

.btn--text {
  padding: 0;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  outline: none;
  border: none;
}

.btn--continue {
  box-shadow: 0 10px 20px 0 #b3b3b3;
}

.btn.outline {
  background: none;

  &.grey {
    border: 1px solid #d4d5d8;
  }

  &.blue {
    border: 1px solid $primaryColor;
  }
}

.btn--success {
  background: #5bb75b;
  color: white;
  border-radius: 75px;
}

.btn.btn--cancel {
  color: $primaryColor;
  background-color: #e5e5e5;
}

.btn--call {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) !important;
  background: #43ab04;
}

.btn {
  &.is-loading {
    color: transparent !important;
    pointer-events: none;

    &::after {
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      position: absolute !important;
      animation: spinAround 0.5s infinite linear;
      border: 2px solid #ffffff;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
    }
  }

  &.btn--default.is-loading::after {
    border: 2px solid #d8d8d8;
  }

  &--link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    // text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    color: inherit;
    box-shadow: none;
    border-radius: 0px;
    text-align: left;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.floating-btn {
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 30px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.65),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 11px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: $primaryColor;
  transition: 200ms all;
  z-index: 5;
  outline: 0px;
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
  @media (max-width: 767px) {
    height: 53px;
    width: 53px;
    font-size: 10px;
    bottom: 15px;
    z-index: 18;
  }
}
.relative {
  position: relative;
}

.instruction-component {
  .instructions {
    background-color: #ffffff;
    background-color: #446c91;
    color: white;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    margin-top: 0px;
    box-shadow: 0 3px 20px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .instr-star {
      display: flex;
      justify-content: center;
      margin-right: 0px;
      img {
        width: 50px;
        height: 50px;
        position: relative;
        top: -7px;
        left: -5px;
      }
      @media (max-width: 767px) {
        display: none;
      }
      span {
        background: #efce4a;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        align-items: center;
        border-radius: 50%;
      }
    }
    .instr-text {
      width: calc(100% - 45px);
      @media (max-width: 767px) {
        width: auto;
      }
    }
    .instr {
      font-size: 12px;
      line-height: 1.5;
      &-header {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 7px;
        line-height: 1.4;
      }
      &-subheader {
        font-size: 13px;
        line-height: 1.6;
        opacity: 0.8;
        margin-bottom: 7px;
        // max-width: 450px;
        &.no-max-width {
          max-width: none;
        }
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
      &-btn {
        background: white;
        color: #446c91;
      }
    }
  }
}

.show-contact-btn.btn.is-loading::after,
.lead-viewed-hired > .btn.is-loading::after {
  border: 2px solid #f2784b;
}

.disabled-form {
  cursor: default !important;
  pointer-events: none !important;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
    z-index: 2;
  }
}

.err-alert {
  color: #ff2c2d !important;
  padding: 7px 0px;
  animation-name: alert-bounce;
  transform-origin: center bottom;
  animation-duration: 1500ms;
}

.info-alert {
  color: #232f49;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  display: block;
  border: 1px solid #5bc0de;
  background: rgba(91, 192, 222, 0.2);
  font-weight: 500;
  box-shadow: 0 3px 20px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.error-alert {
  color: #232f49;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  display: block;
  border: 1px solid #de675b;
  background: rgba(222, 113, 91, 0.2);
  font-weight: 500;
  box-shadow: 0 3px 20px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);
  line-height: 1.5;

  span {
    font-weight: 400;
  }
}

@keyframes alert-bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* checkbox */

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.checkbox {
  margin-top: -8px;

  &:hover {
    cursor: pointer;
  }
}

.checkbox__input {
  display: none;

  &:checked + .checkbox__label::before {
    content: "\2713";
    font-size: 13px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    padding-left: 1px;
    font-weight: bold;
    color: #232f49;
    border-color: #232f49;
  }
}

.checkbox__label {
  position: relative;
  transition: all 0.3s ease-in-out;
  padding-left: 26px;
  display: block;
  font-size: 13px;
  line-height: 1.38;
  color: #61727b;

  &::before {
    position: absolute;
    content: " ";
    width: 15px;
    height: 15px;
    left: 0;
    top: 2px;
    border-radius: 2px;
    border: 1px solid #d4d5d8;
    background-color: #d4d5d8;
    transition: all 0.3s ease-in-out;
  }
}

.checkbox__label--morePad {
  padding-left: 30px;
}

.checkbox__label:hover {
  cursor: pointer;
}

/* radio */

.radio-items input {
  display: none;

  &:checked + .radio-items__label::before {
    content: " ";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 1px #ccc;
    padding: 3px;
    background-clip: content-box;
    background-color: #232f49;
  }

  &.ninBox {
    display: block;
    font-size: 12px;
  }
}

.radio-items__label {
  position: relative;
  padding-left: 26px;
  margin: 10px 0;
  line-height: 1.29;

  &::before {
    position: absolute;
    content: " ";
    width: 15px;
    height: 15px;
    left: 0;
    top: 1px;
    border-radius: 50%;
    border: 1px solid #d4d5d8;
    transition: all 0.3s ease-in-out;
  }
}

/* switch */

.bo-switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;

  input {
    display: none;

    &:checked + .bo-switch-track {
      // background-color: #09d963;
      background-color: #015fd1;
    }

    &:focus + .bo-switch-track {
      // box-shadow: 0 0 1px #09d963;
      box-shadow: 0 0 1px #015fd1;
    }

    &:checked + .bo-switch-track:before {
      -webkit-transform: translateX(31px);
      -ms-transform: translateX(31px);
      transform: translateX(31px);
    }
  }
}

.bo-switch-track {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e1e1;
  -webkit-transition: 0.4s ease-in 0s;
  transition: 0.4s ease-in 0s;
  border-radius: 25px;

  &:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 1.5px;
    bottom: 2px;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: 0.4s ease-in 0s;
    transition: 0.4s ease-in 0s;
  }
}

.form-switch {
  &-small {
    .bo-switch {
      width: 44px;
      height: 18px;

      input {
        display: none;

        &:checked + .bo-switch-track {
          // background-color: #09d963;
          background-color: #015fd1;
        }

        &:focus + .bo-switch-track {
          // box-shadow: 0 0 1px #09d963;
          box-shadow: 0 0 1px #015fd1;
        }

        &:checked + .bo-switch-track:before {
          -webkit-transform: translateX(25px);
          -ms-transform: translateX(25px);
          transform: translateX(25px);
        }
      }
    }
    .bo-switch-track {
      border-radius: 17px;
      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
      }
    }
  }
}

.star-container {
  margin: 0px -2px;

  svg {
    margin: 0px 2px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.block {
  display: block;
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

/* panel */

.panel {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 3px 20px rgba(71, 71, 73, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  padding: 15px;
}

.panel-title {
  /* color: $primaryColor; */
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 15px;
  text-transform: capitalize;
}

.center {
  text-align: center;
}
.header {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 15px;
  }
}

.primary {
  color: $primaryColor;
}

.semibold {
  font-weight: 500;
}

/* speech bubble */

.new-bubble {
  position: relative;
  left: 10px;
  background: #fe3e3e;
  border-radius: 0.4em;
  padding: 3px 5px;
  color: #fff;
  font-size: 9px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: #fe3e3e;
    border-left: 0;
    margin-top: -5px;
    margin-left: -5px;
  }
}

/* modals */

.bo-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 24;
}

.bo-modal-body {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 450px;
  background: white;
  border-radius: 5px;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &:focus {
    outline: none;
  }
}

.refund-modal .bo-modal-body-content ul {
  font-size: 14px;
  padding: 0px;
  margin: 0px;

  li {
    &:before {
      content: "\2022";
      position: relative;
      left: 0px;
      position: absolute;
      position: absolute;
      top: -1px;
    }

    &:not(:last-of-type) {
      padding-bottom: 14px;
    }
  }
}

.close-refund-modal {
  padding: 20px 0px 15px;
  display: flex;
  justify-content: center;

  .btn {
    border-radius: 32px;
    padding: 9px 20px;
  }
}

.subscribe-modal .bo-modal-body-header-close {
  font-size: 20px;
  margin: -7px 0px;
  position: relative;
  top: -2px;
}

.subscribe-icon {
  display: block;
  max-width: 200px;
  margin: 0px auto 15px;
  display: flex;
  justify-content: center;
}

.subscribe-header {
  line-height: 1.5;
  margin-bottom: 20px;
}

.refund-modal .bo-modal-body-content ul li {
  display: flex;
  align-items: baseline;
  position: relative;
  padding-left: 15px;
  line-height: 1.5;
}

.contact-modal .contact-modal-header {
  text-align: left;
  font-size: 15px;
  line-height: 1.5;
  padding-top: 15px;
}

/* .contact-modal .bo-modal-body {
    width: 350px;
 } */

.contact-modal-detail {
  padding-top: 10px;
}

.contact-modal {
  .contact-modal-header b {
    font-weight: 500;
  }

  .contact-modal-buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto 15px;

    .btn--cancel {
      margin-right: 30px;
      min-width: 60px;
    }
  }

  .contact-modal-text {
    color: #5e7079;
    text-align: center;
    padding-bottom: 10px;

    /* font-style: italic; */
    font-size: 12px;
  }
}

.hired-modal-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;

  /* color: $primaryColor; */
  padding: 8px 0px 0px;
  text-align: center;
}

.hired-modal-subheader {
  max-width: 340px;
  margin: 8px auto 0px;
  font-size: 14px;
  line-height: 1.5;
  color: #5e7079;
  text-align: center;
}

.hired-modal-icon {
  margin: 0 auto 15px;
  display: block;
  height: 150px;
}

.hired-modal-header span {
  display: block;
  margin: 4px 0px;
  font-size: 13px;
  font-weight: 400;
  color: #5e7079;
}

.hired-modal-buttons {
  .btn--cancel {
    margin-right: 30px;
  }

  display: flex;
  justify-content: center;
  margin: 20px auto 10px;
}

.guide-modal-body {
  position: absolute;
  padding-top: 30px;
  color: white;
  text-align: center;
}

.guide-modal-body-icon img {
  transform: rotate(320deg);
  width: 100px;

  /* margin-left: -30px; */
}

.guide-element {
  position: relative;

  /* right: -50px;
 top: -20px; */
  display: flex;
  justify-content: flex-end;
  top: -30px;
  right: -10px;
}

.guide-modal-body-cont {
  /* margin-left: -30px; */
  max-width: 200px;
  padding: 0px 20px;

  button {
    margin-top: 20px;
    background: none;
    color: white;
    border: 2px solid #f1f1f1;
    border-radius: 32px;
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    padding: 9px 26px;
    transition: 200ms all;

    &:hover {
      background-color: #ffffff;
      color: $primaryColor;
    }
  }
}

.guide-modal-header {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;

  /* text-shadow: 0px 0px 2px #000000; */
}

.guide-modal-subheader {
  max-width: 240px;
  font-size: 15px;
  line-height: 1.5;
  text-shadow: 0px 0px 2px #000000;
}

.guide-element img {
  height: 20px;
  width: 20px;

  /* padding: 6px 5px;
 background: #ffffff; */
}

.err-container {
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.err-container-retry-header {
  padding-bottom: 15px;
  font-weight: 500;
}

@media only screen and (max-width: 450px) {
  .bo-modal-body {
    width: 77% !important;
    top: 50%;
  }

  .contact-modal .contact-info-action-button.btn {
    min-width: 40px !important;
  }
}

.bo-modal-body-header {
  margin: 0px -20px;
  padding: 0px 20px 15px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small {
  font-size: 11px;
}
.smaller {
  font-size: 13px;
}
.heading-text {
  font-weight: 500;
  padding-bottom: 7px;
}
.normal-size {
  font-size: 14px;
}

.bo-modal-body-header-title {
  font-size: 17px;
  color: #232f49;
  font-weight: 500;
  // @media (max-width: 1014px) {
  //   font-size: 18px;
  //   font-weight: 400;
  // }
}

.bo-modal-body-header-close {
  cursor: pointer;
  font-size: 25px;
  opacity: 0.6;
  margin: -5px 0px;
}

.bo-modal-body-content {
  padding: 20px 0px 5px;
  max-height: 330px;
  overflow-y: auto;
  transition: 200ms height;
}

.bo-modal-body-footer {
  margin: 5px -20px -5px;
  padding: 15px 20px 0px;
  border-top: 1px solid #f1f1f1;

  .btn {
    display: block;
  }
}

.hidemodal,
.hidecontent {
  opacity: 0;
  visibility: hidden;

  /* transition: opacity ease-in .3s; */
}

.showmodal,
.showcontent {
  opacity: 1;
  visibility: visible;
  transition: opacity ease-in-out 0.3s;
}

.hidecontent {
  height: 0px;
  overflow: hidden;
}

.showcontent {
  transition: 200ms height;
  height: auto;
  overflow: auto;
}

.image-modal .bo-modal-body {
  background: transparent;

  img {
    width: 100%;
    object-fit: cover;
    height: auto;
    max-height: 400px;
  }
}

.alert-modal .bo-modal-body {
  width: 300px;
}

.offline-modal .bo-modal-body {
  width: 320px;
}

.offline-modal-icon {
  display: flex;
  justify-content: center;

  img {
    width: 75px;
    height: 75px;
  }
}

.offline-modal-list {
  ul {
    padding: 0px;

    li {
      line-height: 20px;
      position: relative;
      padding-left: 25px;

      &:not(:last-of-type) {
        padding-bottom: 10px;
      }

      &:before {
        content: "\2713";
        position: absolute;
        color: white;
        background: #0dd471;
        width: 20px;
        height: 20px;
        font-size: 10px;
        left: -5px;
        border-radius: 50%;
        text-align: center;
      }
    }
  }

  max-width: 300px;
  margin: 10px auto 30px;
}

@media (max-width: 450px) {
  .image-modal .bo-modal-body {
    img {
      width: 100%;
      max-height: 450px;
      object-fit: cover;
    }

    width: 100% !important;
  }
}

.closeimagemodal {
  color: white;
  position: absolute;
  top: -40px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
  padding: 20px;

  &:hover span {
    -webkit-animation: spin 1s linear;
    -moz-animation: spin 1s linear;
    animation: spin 1s linear;
  }

  span {
    display: block;
  }
}

.bo-ripple {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: inherit radial-gradient(circle, transparent 1%, #5378a0 1%)
      (center / 15000%);
  }

  &:active {
    background-color: #a7cdf5;
    background-size: 100%;
    transition: background 0s;
  }
}

/* animations */

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.ripple:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

.bo-bounce {
  -webkit-animation-name: bo-bounce;
  animation-name: bo-bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
}

.bo-bounce-up {
  -webkit-animation-name: bo-bounce-up;
  animation-name: bo-bounce-up;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

@keyframes bo-bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@keyframes bo-bounce-up {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -10px, 0);
  }

  75% {
    transform: translate3d(0, 5px, 0);
  }

  90% {
    transform: translate3d(0, -3px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bo-underline {
  position: relative;

  &:hover {
    text-decoration: none !important;

    &:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 1px;
    left: 0;
    background-color: $primaryColor;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 400ms ease-in-out 0s;
    transition: all 400ms ease-in-out 0s;
  }
}

.form__search {
  margin-bottom: 15px;
}

.form__label {
  display: inline-block;
  font-size: 13px;
  line-height: 1.69;
  color: #61727b;
  margin-bottom: 6px;
  font-weight: 400;
}

.form__label--span {
  display: inline-block;
}

.form__input {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 15px;
  line-height: 1.69;
  color: #55595c;
  font-family: inherit;
  background-color: #fff;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #d4d5d8;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  resize: none;
  transition: border-color ease-in-out 0.3s;

  &:focus {
    border-color: #232f49;
  }

  &[readonly],
  &[disabled] {
    background-color: #f4f7fc;
    opacity: 0.8;
    pointer-events: none;
  }

  &[readonly]:focus,
  &[disabled]:focus {
    border-color: #d4d5d8;
  }
}

.form__input--right {
  padding-right: 60px;
}

.form__input--iconLeft {
  padding-left: 40px;
}

.form__header {
  font-size: 22px;
  margin-bottom: 16px;
}

.form__title {
  font-size: 22px;
  margin-bottom: 14px;
  color: #232f49;
  font-weight: 400;
}

input {
  &[type="date"],
  &[type="datetime"] {
    -moz-appearance: none !important;
  }
}

.btn[disabled] {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

@media screen and (min-width: 1015px) {
  .form__title {
    border-bottom: 1px solid #d4d5d8;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }
}

.form__extrainfo {
  border-radius: 3px;
  background-color: #5e7079;
  color: #fff;
  line-height: 1.17;
  font-size: 12px;
  padding: 15px 16px;

  &.light__bg {
    background-color: #fff;
    color: #232f49;
    padding: 0;
  }
}

.form__item {
  margin-bottom: 20px;
}

.form__item--2 {
  margin-bottom: 30px;
}

.form__item__caption {
  color: $primaryColor;
  text-decoration: underline;
  margin-top: 2px;
  display: block;
  font-size: 13px;
  line-height: 1.69;

  &.bordered__link {
    border-bottom: 1px solid $primaryColor;
    text-decoration: none;
  }
}

.form__item__flex {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;

  a {
    display: inline-block;
  }

  &.with__multiple__line {
    justify-content: center;
    margin-top: 8px;
  }
}

@media screen and (min-width: 1015px) {
  .form__item__flex.with__multiple__line {
    position: relative;
    bottom: 34px;
  }
}

.form__item.last-form-item {
  margin-bottom: 90px;
}

.form__section {
  padding-bottom: 45px;
}

.form__pad {
  padding: 22px 24px;
}

.form__proceed {
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}

.form__show {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 20px;
  display: flex;
  align-items: center;
}

.form__icon {
  position: absolute;
  left: 12px;
  top: 14px;
}

.form__info {
  background-color: #5e7079;
  padding: 14px 16px;
  color: #fff;
  line-height: 1.17;
  font-size: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.form__footer {
  padding: 16px 24px;
  border-top: 1px solid #d4d5d8;
  text-align: right;
}

.form__footer--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-items label {
  display: block;
}

.select__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 12px;
  font-size: 16px;
  line-height: 1.38;
  color: inherit;
  display: block;
  width: 100%;
  font-family: inherit;
  border: 1px solid #d4d5d8;
  border-radius: 4px;
  outline: none;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  transition: border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;

  &:focus {
    border-color: #232f49;
  }
}

.select-wrapper {
  position: relative;
}

.no-switch-wrapper {
  width: 100%;
}

.alert-subheader {
  ol {
    padding-left: 10px;
    text-align: left;
    margin: 0px;
  }

  li {
    padding-bottom: 10px;
    line-height: 1.5;
  }
}

.select-wrapper::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #5e7079;
  right: 15px;
  z-index: 1;
  transform: translateY(-50%);
  top: 50%;
  opacity: 0.3;
  pointer-events: none;
}

/*components end */

/* main body structure */

.switch-wrapper {
  position: relative;

  > div {
    position: absolute;
  }
}

.bo-body {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.bo-body-sidebar {
  width: 240px;
  background-color: #ffffff;
  height: 100%;
  color: #232f49;
  position: fixed;
  top: 60px;
  z-index: 6;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);

  &-bottom {
    position: fixed;
    width: 240px;
    bottom: 0;
    background: #fff;
    &-onlineswitch {
      padding: 3px 13px 3px 18px;
      border-top: 1px solid #f1f1f1;
    }
  }
}

.Toastify__toast {
  border-radius: 4.5px !important;
  min-height: 65px !important;
}

.Toastify__toast--success.success-toast {
  background: #5bb75b;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100% !important;
    margin: 0px !important;
  }

  .Toastify__toast {
    border-radius: 0px !important;
    min-height: 65px !important;
  }
}

@media (max-width: 1014px) {
  .guide-element {
    right: auto;
    left: -10px;
  }

  .guide-modal-body-icon {
    right: auto;
    left: -18px;
  }

  .bo-body-sidebar {
    top: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
    &-bottom {
      width: 85%;
      max-width: 310px;
      bottom: 0;
    }
    /* background-color: rgba(0, 0, 0, 0.3); */
    background: transparent;
    z-index: 25;
    left: -100%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform: translateX(0) translateZ(0);
    transform: translateX(0) translateZ(0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: 0.2s;
    transition-timing-function: ease-in;
  }

  .anim-sidebar,
  .introjs-fixParent.anim-sidebar {
    transition-timing-function: ease-out;
    transition: 0.25s;
    transform: translateX(100%) !important;
    transform: translateX(100%) translateZ(0) !important;
    transform: translate3d(100%, 0, 0) !important;
    z-index: 25 !important;
  }
}

.App.sidebar-open:before {
  content: "";
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 23;
  background: rgba(0, 0, 0, 0.3);
}

.bo-body-content {
  margin-left: 240px;
  width: calc(100% - 340px);
  padding: 30px 50px;

  /* overflow: hidden; */
}

.bo-body-fixed {
  position: absolute;
  width: 100%;
}

.offline-strip {
  background: #7c7c7c;
  font-size: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
  padding: 3px 0px;
  overflow: hidden;
}

.switch-strip {
  background: #ffffff;
  font-size: 13px;

  /* text-decoration: underline; */
  font-weight: 500;
  text-align: center;
  padding: 5px 0px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 12px -2px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  .close-switch {
    text-decoration: none;
    position: absolute;
    transform: translateY(-50%);
    top: 45%;
    opacity: 0.5;
    right: 15px;
    font-size: 15px;
  }

  .switch {
    font-weight: 500;
    img {
      width: 26px;
      height: 10px;
      position: relative;
      bottom: -1px;
      margin-right: 5px;
      object-fit: contain;
    }
  }
}

.offline-strip .indicator,
.switch-strip .indicator {
  background-color: red;
  margin-right: 4px;
  height: 7px;
  width: 7px;
  display: inline-block;
  border-radius: 50%;
}

@media (max-width: 1014px) {
  .fixed-body {
    overflow-y: hidden;
    position: fixed;
  }

  .bo-body-content {
    margin-left: 0px;
    padding: 35px 15px 20px;
    width: calc(100% - 30px);

    /* overflow: hidden; */
  }

  .btn {
    padding: 9px 18px;
  }
}

.error-msg {
  color: #ff0505;
  /* margin-top: -12px; */
  padding-bottom: 8px;
}

.error-input {
  border: 1px solid #ff0505 !important;
}

.delete-item {
  background-color: #ff0505;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  bottom: 1px;

  &:hover {
    opacity: 0.8;
  }
}

.change-item {
  text-decoration: underline;
  font-size: 12px;
  margin-left: 10px;
}

.temp-file-name {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-flex;
}

.update-notification-cont,
.offline-notification-cont {
  // position: fixed;
  // right: 30px;
  // top: 90px;
  // border-radius: 5px;
  // background-color: #ffffff;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.2);
  // z-index: 99;
  height: 70px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50px);
  transform: translateX(-50px) translateZ(0);
  transform: translate3d(-50px, 0, 0);
  transition: all 0.3s ease-in-out;
  position: fixed;
  left: 25px;
  bottom: 30px;
  border-radius: 5px;
  background-color: #fff;
  z-index: 8;
  width: 300px;
  @media (max-width: 767px) {
    width: 100%;
    box-shadow: none;
    bottom: 0;
    left: 0;
    box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: none;
    // border-top: 1px solid #f1f1f1;
    border-radius: 0px;
    height: 65px;
  }
  @media (max-width: 340px) {
    height: 70px;
  }
}

.update-notification,
.offline-notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15px;
}

.update-notification-icon,
.offline-notification-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #09d963;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 20px rgba(50, 50, 93, 0.08),
    0 1px 3px rgba(233, 148, 148, 0.08);
  margin-right: 15px;
  height: 70px;
  padding: 0px 15px;
  @media (max-width: 767px) {
    border-radius: 0px;
  }
  .rotate-icon {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  &.is-error {
    background: #ff0505;
    img {
      height: 30px;
    }
  }
}

.update-notification-icon img {
  height: 20px;
}

.offline-notification-icon img {
  width: 20px;
  width: 30px;
}

.update-notification-text {
  margin-right: 30px;
}

.update-notification-text-top {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 5px;
}

.offline-notification-text-top {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 5px;
  font-weight: 400;
  max-width: 250px;
  font-size: 15px;
  line-height: 1.3;
}

.update-notification-text-bottom span {
  cursor: pointer;
  color: #5e7079;
  font-size: 13px;
}

.update-notification-close {
  position: absolute;
  right: 10px;
  top: 10px;
  img {
    width: 13px;
    opacity: 0.6;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    img {
      width: 12px;
    }
  }
}

.show-update-notification,
.show-offline-notification {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
  transform: translateX(0px) translateZ(0);
  transform: translate3d(0px, 0, 0);
}

.introjs-tooltip {
  padding: 20px !important;
  box-shadow: none !important;
}

.intro-header {
  font-weight: 700;
  color: #3676c8;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 20px;
}

.intro-subheader {
  color: #57584e;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}

.introjs-arrow.left {
  top: 20px !important;
}

.introjs-helperLayer {
  /* background-color: transparent;
    border: none;
    box-shadow: none;
    background: 0 0;
    border: solid #fff;
    border-width: 0 0 0 2px;
    margin-left: 6px;
    border-radius: 0px; */
  background-color: transparent !important;

  /* border: 1px solid rgba(255,255,255, 0.2); */
  border: none !important;
  box-shadow: none !important;
}

.introjs-arrow {
  border: 8px solid transparent !important;

  &.bottom {
    bottom: -16px !important;
  }

  &.top,
  &.top-middle {
    top: -16px !important;
  }

  &.bottom-middle {
    bottom: -16px !important;
  }
}

.introjs-tooltipbuttons {
  display: flex !important;
  justify-content: space-between !important;
}

.introjs-button {
  &:active,
  &:focus {
    background: 0 0 !important;
  }

  &:hover {
    border-color: $primaryColor !important;
    color: $primaryColor !important;
  }

  font-family: inherit !important;
  font-size: 12px !important;
  display: inline-block !important;
  padding: 8px 15px !important;
  border-radius: 32px !important;
  border: solid 1px #f5f5f5 !important;
  background: 0 0 !important;
  transition: all 0.3s !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03) !important;

  /* color: #a4a59a; */
  color: #57584e !important;
  margin-right: 5px !important;

  &.introjs-hidden {
    display: none !important;
  }
}

.introjs-prevbutton.introjs-fullbutton,
.introjs-nextbutton.introjs-fullbutton {
  border: solid 1px #f5f5f5 !important;
  border-radius: 32px !important;
}

.introjs-prevbutton.introjs-fullbutton:hover,
.introjs-nextbutton.introjs-fullbutton:hover {
  border-color: $primaryColor !important;
  color: $primaryColor !important;
}

.App {
  position: relative;
  min-height: 100vh;
}

.footer-modals {
  position: absolute;
  bottom: 0px;
  right: 0px;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.03);

  display: flex;
  @media (max-width: 1014px) {
    position: absolute;
    box-shadow: 0 3px -12px 3px rgba(0, 0, 0, 0.08);
  }
  // display: none;
}

.ril-image-current.ril__image {
  width: 450px;

  /* height: 400px; */
  object-fit: cover;
}

.disable-click {
  pointer-events: none;
}

@media (max-width: 767px) {
  .ril-image-current.ril__image {
    width: 90%;

    /* height: 350px; */
  }
}

.report-issue .form__item,
.report-issue-modal .form__item {
  margin-bottom: 10px;
}

#issue-report {
  .radio-items__label {
    position: relative;
    padding-left: 26px;
    margin: 7px 0 12px;
    line-height: 1.29;

    &:last-of-type {
      margin-bottom: 15px;
    }

    &::before {
      position: absolute;
      content: " ";
      width: 13px;
      height: 13px;
      left: 0;
      top: 1px;

      /* border-radius: 0px; */
      border-radius: 50%;
      border: 1px solid #d4d5d8;
      transition: all 0.3s ease-in-out;
    }
  }

  .radio-items input:checked + .radio-items__label::before {
    content: " ";
    width: 8px;
    height: 8px;

    /* border-radius: 0px; */
    border-radius: 50%;
    border: solid 1px #ccc;
    padding: 3px;
    background-clip: content-box;
    background-color: #446c91;
  }
}

.radio-items__label.small-label {
  position: relative;
  padding-left: 26px;
  margin: 7px 0 12px;
  line-height: 1.29;

  &:last-of-type {
    margin-bottom: 15px;
  }

  &::before {
    position: absolute;
    content: " ";
    width: 13px;
    height: 13px;
    left: 0;
    top: 1px;

    /* border-radius: 0px; */
    border-radius: 50%;
    border: 1px solid #d4d5d8;
    transition: all 0.3s ease-in-out;
  }
}

.radio-items.small-radio
  input:checked
  + .radio-items__label.small-label::before {
  content: " ";
  width: 8px;
  height: 8px;

  /* border-radius: 0px; */
  border-radius: 50%;
  border: solid 1px #ccc;
  padding: 3px;
  background-clip: content-box;
  background-color: #232f49;
}

.light-text {
  opacity: 0.6;
}

.report-issue textarea {
  padding: 7px 10px;
}

.report-issue-modal {
  textarea {
    padding: 7px 10px;
  }

  .bo-modal-body-content {
    padding: 10px 20px 5px;
    margin: 0px -20px;
    max-height: none;
  }
}

.report-issue.visible {
  opacity: 1;
  visibility: visible;
}

.report-issue-modal {
  .bo-modal-body {
    padding: 15px 20px 20px;
  }

  .bo-modal-body-content {
    span {
      padding: 5px 0px 10px;
      display: inline-block;
    }

    .form__label span {
      img {
        height: 15px;
        width: 15px;
        object-fit: contain;
        position: relative;
        margin-right: 7px;
        bottom: -3px;
      }

      padding: 0px;
      display: inline-block;
      cursor: pointer;

      label {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}

.report-issue button {
  border-radius: 32px;
  display: flex;
  justify-content: center;
  max-width: 150px;
  margin: 0 auto;
  min-width: 120px;
}

.report-issue-open {
  a {
    color: inherit;
    text-decoration: none;
  }

  color: $primaryColor;

  /* color: white; */
  display: flex;
  align-items: center;
  padding: 8px 15px 7px;
  cursor: pointer;
  font-size: 13px;
  height: 17px;

  /* color: #232f49; */
  background-color: #ffffff;
  box-shadow: 0 3px 20px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:first-of-type {
    border-top-left-radius: 7.5px;
  }
}

.feedback .report-issue-open {
  border-radius: 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.report-issue-open img {
  width: 14px;
  position: relative;
  margin-right: 5px;
  height: 14px;
  top: -0.5px;
}

.feedback .report-issue-open img {
  bottom: -3px;
  top: auto;
}

.report-issue input#upload__documents {
  display: none;
}

.issue-image-previews {
  display: flex;
  justify-content: flex-start;
  margin: 0px -5px;

  .image-preview {
    width: calc(33% - 10px);
    margin: 5px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 30px;

    img {
      height: 40px;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      @media (min-width: 1015px) {
        height: 70px;
      }
    }

    .remove-image {
      padding: 1px 0px 3px;
      text-align: center;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: 1015px) {
  .all_lead_details > .panel,
  .lead-panel {
    // margin-bottom: 0px;
    // box-shadow: none;
    // border-radius: 0px;
    margin-bottom: 0px !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .pool__body {
    margin-top: 5px !important;
  }

  .bo--leads {
    margin: -30px -50px;
    background-color: white;
  }

  .bo-lead-list-details {
    overflow: hidden;
    margin: 0px !important;
  }

  .bo-lead-details {
    margin: 0px !important;
    margin: 0px;
    width: 65% !important;
  }

  .bo-lead-list {
    margin: 0px !important;
    width: 35% !important;
    border-right: 1px solid #f1f1f1;
    padding: 0px 8px 0px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    transition: 500ms width;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;

    &:hover {
      background: #cccccc;
    }
  }

  .bo-center-content,
  .bo-left-sidebar {
    position: sticky;
    top: 0px;
  }

  .hide--desktop {
    display: none !important;
  }

  .position-relative {
    position: relative !important;
  }
}

.bo-body-content-cont {
  display: flex;
  justify-content: flex-start;
  margin: 0px -15px 30px;
  align-items: flex-start;
}

.bo-left-sidebar {
  width: calc(36% - 15px);
  margin: 15px;
}

.bo-center-content {
  width: calc(64% - 15px);
  margin: 15px;
}

/* .bo-right-sidebar {
     width: calc(27% - 15px);
     margin: 15px;
   } */

.light {
  color: #5e7079;
}

.hide-prompt {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transform: translateY(50px) translateZ(0);
  transform: translate3d(0, 50px, 0);
  transition: all 0.3s ease-in-out;
}

.show-prompt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transform: translateY(0px) translateZ(0);
  transform: translate3d(0px, 0px, 0);
}

.install-prompt,
.subscribe-prompt {
  width: calc(100% - 20px);
  right: 0;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 24;
  text-align: center;
  padding: 10px;
  left: 0;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
}

.subscribe-prompt {
  @media (min-width: 1015px) {
    width: 320px;
    left: auto;
    right: 0;
    border-top-left-radius: 5px;
  }
}

.install-prompt-header,
.subscribe-prompt-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;

  /* color: $primaryColor; */
  padding-top: 5px;
}

.install-prompt-close,
.subscribe-prompt-close {
  position: absolute;
  right: 10px;
  font-size: 19px;
  padding: 15px;
  color: #5e7079;
  top: 5px;
  margin: -10px;
}

.install-prompt-subheader,
.subscribe-prompt-subheader {
  max-width: 500;
  margin: 8px auto 5px;
  font-size: 14px;
  line-height: 1.43;
  color: #5e7079;
  padding: 0px 5px;
}

.install-prompt .btn,
.subscribe-prompt .btn {
  border-radius: 50px;
  margin: 20px auto 10px;
  display: block;
  min-width: 130px;
  background: $primaryColor;
  color: white;
}

.subscribe-prompt-header svg {
  margin-right: 5px;
}

.subscribe-prompt-icon {
  width: 40px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($primaryColor, 0.09);
  max-width: 100px;
  margin: 5px auto;
  border-radius: 50%;
}

.subscribe-prompt {
  a {
    text-decoration: none;
  }

  .btn {
    margin: 10px auto;
  }
}

.install-prompt-buttons .btn {
  background: #5bb75b;
  color: white;
}

.download-page {
  max-width: 420px;
  margin: 0 auto;

  .install-prompt {
    position: static;
    box-shadow: none;
  }
  .install-prompt-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: rgba($primaryColor, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto 10px;
  }

  .install-prompt-icon img {
    height: 30px;
  }
  .install-prompt-buttons {
    text-decoration: none;
    background: $primaryColor;
  }
  .install-prompt-buttons .btn {
    text-decoration: none;
    background: $primaryColor;
  }
  .install-prompt-subheader {
    padding: 0px;
  }
}

.bo-sidebar-body .install-prompt-buttons {
  background: #5bb75b;
  margin: 5px -20px 0px;
  @media (min-width: 1015px) {
    margin: 0px;
  }
}

.install-prompt-icon img {
  height: 42px;
  margin-top: 5px;
}

.install-prompt .btn {
  .size {
    font-size: 13px;
  }

  img {
    height: 20px;
    margin-right: 6px;
    margin-bottom: -4px;
  }
}

.install-prompt-buttons .btn img {
  height: 20px;
  margin-right: 6px;
  margin-bottom: -4px;
}

.flex {
  display: flex;
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.trunc {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.react-joyride__tooltip {
  > div > div {
    padding: 5px 10px !important;
  }

  button {
    svg {
      display: none;
    }

    border-radius: 32px !important;
    padding: 8px 13px !important;
    font-size: 14px !important;
  }
}

@media (max-width: 1014px) {
  .introjs-bottom-left-aligned {
    margin-left: 20px !important;
  }

  .bo-body-content-cont {
    flex-wrap: wrap;
  }

  .bo-left-sidebar,
  .bo-center-content,
  .bo-right-sidebar {
    width: 100%;
  }

  .bo-center-content {
    order: 0;

    &.mbo-center-content {
      margin: 0px;
    }
  }

  .bo-left-sidebar {
    order: 1;
  }

  .bo-right-sidebar {
    order: 2;
  }

  .bo-left-sidebar {
    margin: 15px 15px 0px;
  }

  .show--desktop {
    display: none !important;
  }

  .mobile-lead-detail {
    margin-top: -65px;
  }

  .hide-mobile-header {
    display: none;
  }

  // .update-notification-cont {
  //   top: 80px;
  // }
}

.no-enq {
  margin: 30px auto;
}

.no-enq-img img {
  display: block;
  margin: 0px auto 20px;
  width: 130px;
}

.no-enq-text {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 20px;
  line-height: 23px;
}

@media (max-width: 330px) {
  .introjs-tooltip {
    padding: 15px !important;
    max-width: 250px !important;
  }
}

.hide {
  display: none !important;
}

/* Settings.js Visibility redirect link */

.activation_confirmation_link {
  /* text-decoration: none; */
  margin-top: 10px;
  color: $primaryColor;

  /* border: 1px solid blue;
   border-radius: 10px;
   padding: 6px; */
}

.fixed-button-bottom {
  @media (max-width: 1014px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0px;
    border-top: 1px solid #f1f1f1;
  }
}

.full-page {
  &-header {
    font-size: 15px;
    display: flex;
    align-items: baseline;
    font-weight: 500;
    margin: 0px 0px 0px;
    padding: 7px 15px;
    background: #ffffff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 2px solid rgba(212, 213, 216, 0.5);
    box-shadow: 2px -1px 15px 0 rgba(232, 232, 232, 0.79);
    justify-content: space-between;

    .count {
      color: #61727b;
      font-size: 12px;
      padding-left: 5px;
      position: relative;
      top: 1px;
    }

    &-text {
      padding: 10px 0px;
      font-size: 15px;
      display: flex;
      align-items: center;
      @media (max-width: 1014px) {
        font-size: 18px;
        font-weight: 400;
      }
      span {
        cursor: pointer;

        img {
          width: 17px;
          position: relative;
          margin-right: 10px;
          top: 3px;
        }
      }
    }
  }

  &-body {
    background: #fff;
    padding: 15px;
    margin: 0px;
    box-shadow: 0 3px 20px rgba(71, 71, 73, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);

    &-title {
      margin-bottom: 10px;
      color: #5e7079;
    }
  }

  @media (max-width: 1014px) {
    position: fixed !important;
    padding: 0px 0px;
    width: 100%;
    box-shadow: none;
    top: 0;
    left: 0;
    z-index: 17;
    bottom: 0;
    background: #ffffff;

    .full-page-header {
      margin: 0px 0px 20px;
      padding: 5px 15px;
    }

    .full-page-body {
      padding: 15px 30px 0px;
      margin: -15px -15px 0px;
      overflow-y: scroll;
      height: calc(100vh - 135px);
      box-shadow: none;
    }
  }
}

.fetch-offline-data {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background-color 1s ease 0.3s;
  &.is-open {
    background: rgba(0, 0, 0, 0.125);
  }
  &-content {
    position: fixed;
    left: 25px;
    bottom: 30px;
    border-radius: 5px;
    background-color: #fff;
    // box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 8;
    width: 285px;
    padding: 0px;
    height: 67px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      width: 100%;
      box-shadow: none;
      bottom: 0;
      left: 0;
      box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1);
      // box-shadow: none;
      // border-top: 1px solid #f1f1f1;
      border-radius: 0px;
      height: 65px;
    }
  }

  .rotate-icon {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  &-icon {
    margin-right: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #09d963;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    width: 55px;
    @media (max-width: 767px) {
      height: 65px;
      border-radius: 0px;
    }
    @media (max-width: 340px) {
      height: 70px;
    }
    img {
      height: 30px;
      @media (max-width: 767px) {
        height: 25px;
        width: 25px;
      }
    }
    &.is-error {
      background: #ff0505;
    }
  }
  &-text {
    padding: 0px 15px 0px 0px;
    &-top {
      font-weight: 500;
      padding-bottom: 4px;
    }
    &-bottom {
      color: #5e7079;
      font-size: 13px;
      &-retry {
        text-decoration: underline;
        display: inline-block;
        margin: -10px;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  &-close {
    position: absolute;
    right: 7px;
    top: 7px;
    // display: none;
    @media (max-width: 767px) {
      right: 10px;
    }
    img {
      width: 10px;
      opacity: 0.6;
      cursor: pointer;
    }
  }
  &-anim {
    &-enter {
      opacity: 0;
      transform: translateX(-10px);
    }

    &-enter-active {
      opacity: 1;
      transform: translateX(0px);
      transition: all 300ms;
    }

    &-exit {
      opacity: 0;
      transform: translateX(0px);
    }

    &-exit-active {
      opacity: 0.01;
      transform: translateX(0px);
      transition: all 300ms;
    }
  }
}

.loading-circle {
  border: 1.5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1.5px solid rgba($primaryColor, 0.8);
  width: 22px;
  height: 22px;
  display: block;
  -webkit-animation: spinCircle 1s linear infinite;
  /* Safari */
  animation: spinCircle 1s linear infinite;
  &-light {
    border-top: 1.5px solid rgba(#232f49, 0.4);
  }
}

/* Safari */
@-webkit-keyframes spinCircle {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinCircle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
