@import "../Variables/colors";
.bo-sidebar-body {
   height: calc(100vh - 150px);

   /* margin-top: 10px; */
   /* overflow-y: auto; */
   padding: 0px;
   position: relative;

   ul.bo-sidebar-menu {
      li {
         a {
            p {
               position: relative;
            }

            text-align: center;
            transition: 200ms all;
            display: flex;
            justify-content: flex-start;
            position: relative;

            div {
               padding: 15px 5px 15px 15px;
               font-size: 13px;
               color: inherit;
               text-decoration: none;
            }

            text-decoration: none;
            color: inherit;
            position: relative;
            transition: 200ms all;

            &:hover {
               opacity: 1;
               background: rgba($primaryColor, 0.04);
            }

            &.active {
               background: rgba($primaryColor, 0.1);
               font-weight: 500;
               color: $primaryColor;

               /* border-left: 3px solid #fff; */
            }
         }

         img,
         svg {
            display: block;
            width: 18px;
            margin-right: 8px;
            height: 18px;
         }
      }

      margin: 0px;
      padding: 0px;
      height: 100%;
      overflow: auto;
   }
}

@media (max-width: 1014px) {
   .close-sidebar {
      margin: -15px -15px 10px;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;

      /* letter-spacing: 1.1px; */
      font-size: 17px;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 3;
   }

   .close-sidebar-trigger {
      margin: -5px;
      padding: 5px;
      font-size: 25px;
      opacity: 0.6;
   }

   .bo-sidebar-menu .lead-count {
      right: 20px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
   }

   .bo-sidebar-body {
      margin-top: 0px;
      width: 85%;
      max-width: 270px;
      background-color: #ffffff;
      height: 100vh;
      padding: 5px 20px 20px;
      color: #232f49;
      box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
   }
}

@media (max-width: 1014px) {
   .bo-sidebar-body ul.bo-sidebar-menu {
      height: auto;
      margin: 0px -20px 0px;
      padding-bottom: 20px;
      max-height: calc(100vh - 125px);
   }
}

@media (max-width: 1014px) {
   .bo-sidebar-body ul.bo-sidebar-menu li {
      margin: 0px 20px;

      a {
         margin: 0px -20px;
         justify-content: flex-start;
         padding: 15px 20px;
         text-align: left;

         &:not(:last-of-type) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
         }
      }
   }
}

@media (max-width: 1014px) {
   .bo-sidebar-body ul.bo-sidebar-menu li a {
      div {
         display: flex;
         padding: 0px;
         align-items: center;
      }

      img,
      svg {
         margin-right: 15px;
         margin-bottom: 0px;
      }
   }
}

@media (max-width: 1014px) {
}

.bo-sidebar-user {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   padding-bottom: 10px;
   position: relative;
   margin-top: 0px;
   &-agent {
      border-radius: 5px;
      margin: 0px 0px 0px;
      // box-shadow: 0 5px 4px rgba(0, 0, 0, 0.05);
      padding: 13px 13px 13px 18px;
      // background: #fafafa;
      border-bottom: 1px solid #f1f1f1;
      color: #5e7079;
      font-size: 13px;
      span {
         font-weight: 500;
         // text-transform: lowercase;
         // &:first-letter {
         //    text-transform: uppercase;
         // }
         // color: #232f49;
      }
   }
   &-details {
      margin-left: 10px;
   }
}

.bo-sidebar-user-avatar {
   img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      // margin-right: 15px;
   }

   .user-avatar-placeholder {
      height: 50px;
      width: 50px;
      background-color: #fafafa;

      /* color: $primaryColor; */
      margin-right: 15px;
   }
}

.bo-sidebar-user-details-name {
   font-weight: 500;
   width: 120px;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   font-size: 15px;
   padding-bottom: 4px;
   text-transform: capitalize;
}

.bo-sidebar-user-details-service {
   font-size: 13px;
   text-transform: capitalize;
   width: 120px;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

.bo-sidebar-user-details-id {
   font-size: 13px;
   padding-top: 4px;
   opacity: 0.7;
   width: 120px;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

.bo-sidebar-wallet {
   padding: 10px 0px;
   display: flex;
   justify-content: space-between;
   font-size: 13px;
   align-items: center;
}

.bo-sidebar-wallet-balance {
   .bo-wallet-balance {
      padding-bottom: 5px;
   }

   .bo-wallet-balance-value {
      font-size: 20px;
      font-weight: 500;
   }
}

.bo-sidebar-wallet-fund a.btn {
   /* color: $primaryColor; */
   background: white;
   border-radius: 100px;
   font-size: 15px;
   padding: 8px 21px;
   font-weight: 600;
}

.bo-sidebar-switch {
   padding-left: 20px;
}

.bo-sidebar-switch-top {
   display: flex;
   justify-content: space-between;
   position: relative;
   padding: 15px 0px 10px;
   align-items: center;
}

.bo-sidebar-switch-icon img {
   width: 25px;
   position: relative;
   top: 2px;
}

.bo-sidebar-switch-bottom {
   .bo-sidebar-switch-options {
      background-color: #fafafa;
      margin: 0px -20px;
      top: 0px;
      max-height: 188px;
      overflow-y: auto;
      padding: 0px 0px;

      ul {
         padding: 0px;
         margin: 0px;

         li {
            padding: 15px 20px 15px;
            font-weight: 400;
            text-transform: capitalize;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);

            a {
               text-decoration: none;
               cursor: pointer;
            }
         }
      }
   }

   .manage-business {
      background-color: #446c91;
      margin: 0px -20px;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;

      a {
         text-decoration: none;
         position: relative;
         color: inherit;
         font-weight: 500;
      }
   }
}

.bo-sidebar-menu .lead-count {
   background-color: rgb(74, 144, 226);
   color: #ffffff;
   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
   margin-left: 7px;
   padding: 0px 4px;
   border-radius: 10px;
   opacity: 1;
   min-width: 22px;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -ms-flex-pack: center;
   justify-content: center;
   font-size: 12px;
}

.market-menu {
   position: relative;
}

@media (min-width: 1015px) {
   .bo-sidebar-body ul.bo-sidebar-menu li a.active {
      background: rgba($primaryColor, 0.1);
   }

   .close-sidebar,
   .bo-sidebar-user,
   .bo-sidebar-switch,
   .bo-sidebar-wallet {
      display: none;
   }

   .bo-sidebar-menu .lead-count {
      position: absolute;
      top: 17px;
      right: 15px;
      padding: 1px 4px;
      font-size: 11px;

      /* color: white;
     background: transparent; */
   }

   .bo-sidebar-body ul.bo-sidebar-menu li a div {
      position: relative;
      margin-left: 0px;
      width: 100%;
      display: flex;
      align-items: center;
   }

   .bo-sidebar-user.show--desktop {
      display: block !important;
   }
}
