@import "../Variables/colors";
.sidebar-list-item {
   position: relative;
   a {
      cursor: pointer;
   }
   &-toggle {
      position: absolute;
      right: 5px;
      transform: translateY(-50%);
      top: 50%;
      img {
         height: 13px !important;
         width: 13px !important;
         opacity: 0.6;
      }
   }
   &-name {
      padding-right: 15px;
   }
   &-sub {
      background: #fafafa;
      border-bottom: 1px solid #f1f1f1;
      border-top: 1px solid #f1f1f1;
      @media (max-width: 1014px) {
         width: 100%;
         margin: 0px -20px;
         padding: 0px 20px;
      }
      &-link {
         padding: 12px 15px 12px 40px;
         @media (max-width: 1014px) {
            padding-left: 54px !important;
         }
         &.active-sub {
            color: $primaryColor !important;
         }
      }
   }
}
