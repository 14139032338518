@font-face {
   font-family: "Roboto";
   font-display: auto;
   src: url("Fonts/Roboto-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   font-display: auto;
   src: url("Fonts/Roboto-Medium.ttf");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   font-display: auto;
   src: url("Fonts/Roboto-Bold.ttf");
   font-weight: 700;
   font-style: normal;
}

body {
   margin: 0;
   padding: 0;
   font-size: 14px;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   color: #232f49;
   background-color: #fafafa;
}

// @import "../public"
